import React from 'react';
import './App.css';

import punesisImg from './assets/punesis.jpg'

function App() {
  const appStyle = {
    background: `url(${punesisImg}) center center`,
    backgroundSize: 'cover',
  }

  return (
    <div className="App" style={appStyle}>
      <span>punesis</span>
    </div>
  );
}

export default App;
